export const navitems = [
    {
        number: '0',
        text: 'HOME',
        path: 'home'
    },
    {
        number: '1',
        text: 'ABOUT',
        path: 'about'
    },
    {
        number: '2',
        text: 'WORK',
        path: 'work'
    },
    {
        number: '3',
        text: 'TESTIMONIALS',
        path: 'testimonials'
    },
    {
        number: '4',
        text: 'EDUCATION',
        path: 'education'
    },
    {
        number: '5',
        text: 'CONTACT',
        path: 'contact'
    },
    {
        number:'6',
        text: 'BLOG',
        path: 'blog'
    },
]